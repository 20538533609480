import React from 'react'
import Icon from '../icon'

const ButtonFile = (props) => {

  const {doc, className} = props

  return(
    <div className={className}>
      <a href={doc.uri.url} alt={doc.filename} target="_blank">
        <Icon icon="icons-32-x-32-export-download" size={24} /> {doc.filename.replace(/\.pdf/g, "").replace(/-/g, " ")}
      </a>
    </div>
  )
}

export default ButtonFile
